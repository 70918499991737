import CalButton from '@/components/features/CalButton';

export function ButtonDiscoveryCall() {
	return (
		<CalButton
			link={'adamcrowe'}
			namespace={'discovery-call'}
			btnText={'Book a discovery call'}
		/>
	);
}
