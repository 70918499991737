'use client';

import {
	Popover,
	PopoverBackdrop,
	PopoverButton,
	PopoverPanel,
} from '@headlessui/react';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import hyflowLogo from '/public/logos/hyflow-logo-b.svg';

import { Container } from '@/components/Container';
import { SITE } from '@/config';
import { ButtonDiscoveryCall } from '../features/ButtonDiscoveryCall';

function MobileNavLink({
	href,
	children,
}: {
	href: string;
	children: React.ReactNode;
}) {
	return (
		<PopoverButton as={Link} href={href} className="block w-full p-2">
			{children}
		</PopoverButton>
	);
}

function MobileNavIcon({ open }: { open: boolean }) {
	return (
		<svg
			aria-hidden="true"
			className="size-3.5 overflow-visible stroke-white/70"
			fill="none"
			strokeWidth={2}
			strokeLinecap="round"
		>
			<path
				d="M0 1H14M0 7H14M0 13H14"
				className={clsx(
					'origin-center transition',
					open && 'scale-90 opacity-0'
				)}
			/>
			<path
				d="M2 2L12 12M12 2L2 12"
				className={clsx(
					'origin-center transition',
					!open && 'scale-90 opacity-0'
				)}
			/>
		</svg>
	);
}

function MobileNavigation() {
	return (
		<Popover>
			<PopoverButton
				className="ui-not-focus-visible:outline-none relative z-10 flex size-8 items-center justify-center"
				aria-label="Toggle Navigation"
			>
				{({ open }) => <MobileNavIcon open={open} />}
			</PopoverButton>
			<PopoverBackdrop
				transition
				className="fixed inset-0 bg-slate-300/50 duration-150 data-[closed]:opacity-0 data-[enter]:ease-out data-[leave]:ease-in"
			/>
			<PopoverPanel
				transition
				className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5 data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in"
			>
				{/* <MobileNavLink href="/use-cases">Use Cases</MobileNavLink>
				<MobileNavLink href="/tools">Tools</MobileNavLink> */}
				<MobileNavLink href="/contact">Contact</MobileNavLink>
			</PopoverPanel>
		</Popover>
	);
}

export function Header() {
	return (
		<header className="py-10">
			<Container>
				<nav className="relative z-50 flex items-center justify-between font-medium text-white">
					<div className="flex items-center justify-start text-2xl font-semibold sm:basis-1/4 md:gap-x-12">
						<div className="flex items-center gap-3">
							<Image
								src={hyflowLogo}
								alt="Hyflow Logo"
								aria-hidden="true"
								className="size-10"
								priority={true}
							/>

							<Link href="/" aria-label="Home">
								{SITE.brand}
							</Link>
						</div>
					</div>
					<div className="hidden text-base md:flex md:gap-x-6">
						{/* <NavLink href="/use-cases">Use Cases</NavLink>
						<NavLink href="/tools">Tools</NavLink>
						<NavLink href="/contact">Contact</NavLink> */}
					</div>
					<div className="flex items-center justify-end gap-4 sm:basis-1/4">
						<div className="hidden sm:block">
							<ButtonDiscoveryCall />
						</div>
						<div className="-mr-1 md:hidden">
							<MobileNavigation />
						</div>
					</div>
				</nav>
			</Container>
		</header>
	);
}
