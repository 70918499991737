import clsx from 'clsx';

export function Container({
	className,
	...props
}: React.ComponentPropsWithoutRef<'div'>) {
	return (
		<div
			className={clsx(
				'mx-auto max-w-screen-3xl px-4 font-sans sm:px-6 lg:px-8',
				className
			)}
			{...props}
		/>
	);
}
