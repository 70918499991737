module.exports.SITE = {
	brand: 'Hyflow',
	company: 'Hyflow Limited',
	origin: 'https://www.hyflow.io',
	basePathname: '/',
	trailingSlash: false,
	title: 'Hyflow | AI-automated apps and workflows for savvy businesses',
	// description: 'AI-automated apps and workflows for savvy businesses',
	description: 'Put AI automation to work in your business',
	heroTitle: 'Less work, more flow',
	heroTitleRegular: 'Less work,',
	heroTitleHighlight: 'more flow',
	// heroDescription:
	// 	'Put AI automations to work in your business to clear bottlenecks, eliminate delays, and purge inefficiencies.',
	heroDescription:
		'Clear bottlenecks, eliminate delays, and purge inefficiencies.',
	email: 'info@hyflow.io',
	phone: '0',
	companyAddress:
		'71-75 Shelton Street Covent Garden, WC2H 9JQ, UNITED KINGDOM',
	companyNumber: '16007218',
	bookingDiscovery: 'https://cal.com/adamcrowe/discovery-call',
	bookingDemo: 'https://cal.com/adamcrowe/demo-call',

	// pageTitle:
	// 	'Mixed Martial Arts Training for Self-Defence | Shockwave Jujitsu',
	// metaDescription:
	// 	'Join our beginner-friendly self-defence classes in Ipswich and become that rare person who can handle themselves in any threatening situation.',
	// homePageTitle:
	// 	'Mixed Martial Arts Training for Self-Defence | Shockwave Jujitsu',
	// homeMetaDescription:
	// 	'Join our beginner-friendly self-defence classes in Ipswich and become that rare person who can handle themselves in any threatening situation.',
	// aboutPageTitle:
	// 	'About Shockwave Jujitsu and Close Quarters Self-Defence? | Shockwave Jujitsu',
	// aboutMetaDescription:
	// 	'Join our beginner-friendly self-defence classes in Ipswich and meet like-minded people learning mixed martial arts for close quarters self-defence.',
	// syllabusPageTitle:
	// 	'Our Syllabus: Mixed Martial Arts Training for Self-Defence | Shockwave Jujitsu',
	// syllabusMetaDescription:
	// 	'Explore the five skillsets that will prepare you to prevail against modern day violence. Join our next 4 Weeks Beginners Course to sample the syllabus.',
	// classesPageTitle:
	// 	'Mixed Martial Arts Self-Defence Classes in Ipswich | Shockwave Jujitsu',
	// classesMetaDescription:
	// 	'Discover what to expect on your free trial class on Sunday 10:00-12:00 at the Murrayside Community Centre, Ipswich. No martial arts experience is required.',
	// contactPageTitle: 'Contact Our Head Instructor | Shockwave Jujitsu',
	// contactMetaDescription:
	// 	'Contact us with any questions not answered by our FAQs.',
	// coursesPageTitle:
	// 	'4 Week Beginners Self-Defence Courses | Shockwave Jujitsu',
	// coursesMetaDescription:
	// 	'Join our next 4 week Beginners Course. Our course is ideal if you have previously dismissed the idea of yourself as a martial arts person.',
	// faqsPageTitle:
	// 	'FAQs To Help You Choose A Martial Arts School | Shockwave Jujitsu',
	// faqsMetaDescription:
	// 	'Read our comprehensive FAQs to check that our Mixed Martial Arts Self-Defence training is a good fit for you.',
	// reviewsPageTitle: 'Reviews From Our Students | Shockwave Jujitsu',
	// reviewsMetaDescription:
	// 	'Read what our students have to say about the quality of self-defence training they have received.',
	// workshopsPageTitle:
	// 	'Self-Defence Workshops For Public-Facing Workers | Shockwave Jujitsu',
	// workshopsMetaDescription:
	// 	'Book self-defence training for your public-facing employees to demonstrate you care about their personal safety and wellbeing at work.',
};
